import axiosConfig from '../config/axios';
import axios from 'axios';
import { paths } from '../constant/paths'

export const userApi = {};

userApi.login = (data) => {
  return axios({
    method: "POST",
    url: "/api/" + paths.login,
    data
  });
}

userApi.getBasicStat = async () => {
  return await axiosConfig({
    method: "GET",
    url: paths.getBasicStat,
  });
}

userApi.checkLogin = async () => {
  return await axiosConfig({
    method: "GET",
    url: paths.get
  });
}

userApi.sendAnswer = async (data) => {
  return await axiosConfig({
    method: "POST",
    url: paths.sendAnswer,
    data
  });
}

userApi.getSkillData = async () => {
  return await axiosConfig({
    method: 'GET',
    url: paths.skillData
  });
}

userApi.changePassword = async(data)=>{
  return await axiosConfig({
    method: "POST",
    url: `${paths.user}/changePassword`,
    data
  })
}

userApi.editProfile = async(data)=>{
  return await axiosConfig({
    method: "POST",
    url: `${paths.user}/editProfile`,
    data
  })
}

userApi.checkPassword = async(data)=>{
  return await axiosConfig({
    method:"POST",
    url: `${paths.user}/checkPassword`,
    data
  })
}