import { types } from '../types';

export const setOpenSnackbarState = (message) => ({
  type: types.SET_OPEN_SNACKBAR_STATE,
  payload: {
    message
  }
});

export const setCloseSnackbarState = () => ({
  type: types.SET_CLOSE_SNACKBAR_STATE
})

export const getQuizzes = (message) => ({
  type: types.GET_QUIZ,
  payload: {
    message
  }
});

export const setTimeQuiz = (message) => ({
  type: types.SET_TIME_QUIZ,
  payload: {
    message
  }
})

export const setListQuiz = (message) => ({
  type: types.SET_LIST_QUIZ,
  payload: {
    message
  }
})

export const setListAnswer = (message) => ({
  type: types.SET_LIST_ANSWER,
  payload: {
    message
  }
});

export const setAllSolution = (message) => ({
  type: types.SET_ALL_SOLUTION,
  payload: {
    message
  }
});

export const setIncorrectSolution = (message) => ({
  type: types.SET_INCORRECT_SOLUTION,
  payload: {
    message
  }
});

export const setListAnswerWrong = (message) => ({
  type: types.SET_LIST_ANSWER_WRONG,
  payload: {
    message
  }
})

export const setIndexSolution = (message) => ({
  type: types.SET_INDEX_SOLUTION,
  payload: {
    message
  }
});

export const setListReviewQuestion = (message) => ({
  type: types.SET_LIST_REVIEW_QUESTION,
  payload: {
    message
  }
});

export const setIndexViewQuestion = (message) => ({
  type: types.SET_INDEX_VIEW_QUESTION,
  payload: {
    message
  }
});