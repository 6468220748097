export const types = {
  'GET_QUIZ': 'GET_QUIZ',
  'SET_TIME_QUIZ': 'SET_TIME_QUIZ',
  'SET_LIST_QUIZ': 'SET_LIST_QUIZ',
  'SET_LIST_ANSWER': 'SET_LIST_ANSWER',
  'SET_ALL_SOLUTION': 'SET_ALL_SOLUTION',
  'SET_INCORRECT_SOLUTION': 'SET_INCORRECT_SOLUTION',
  'SET_LIST_ANSWER_WRONG': 'SET_LIST_ANSWER_WRONG',
  'SET_INDEX_SOLUTION': 'SET_INDEX_SOLUTION',
  'SET_LIST_REVIEW_QUESTION': 'SET_LIST_REVIEW_QUESTION',
  'SET_INDEX_VIEW_QUESTION': 'SET_INDEX_VIEW_QUESTION',
  'SET_OPEN_SNACKBAR_STATE': 'SET_OPEN_SNACKBAR_STATE',
  'SET_CLOSE_SNACKBAR_STATE': 'SET_CLOSE_SNACKBAR_STATE'
}