import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { setCloseSnackbarState } from '../store/actions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SnackbarCustom(props) {
  const { snackbarState, setCloseSnackbarState } = props;
  const { open, vertical, horizontal, message } = snackbarState;

  const handleClose = () => {
    setCloseSnackbarState();
  }

  return (
    <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {message}
      </Alert>
    </Snackbar>)
}

const mapState = (state) => ({
  snackbarState: state.snackbarState
})

const mapDispatch = {
  setCloseSnackbarState
};

export default connect(mapState, mapDispatch)(SnackbarCustom);