import './App.css';
// import { ThemeProvider } from '@material-ui/core/styles';
// import theme from './theme';
import Routes from './Routes';
import { store } from './store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store}>
      {/* <ThemeProvider theme={theme}> */}
      <Routes />
      {/* </ThemeProvider> */}
    </Provider>
  );
}

export default App;
