import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProtectedRoute from './routers/ProtectedRoute';
import { Redirect } from 'react-router';
import { Box, LinearProgress } from '@material-ui/core';
import SnackbarCustom from './common/SnackbarCustom';

const Login = lazy(() => import('./components/Login'));
const HomePage = lazy(() => import('./components/HomePage'));
const CreateCustomQuiz = lazy(() => import('./components/CreateCustomQuiz'));
const DoExam = lazy(() => import('./components/DoExam'));
const QuizSummary = lazy(() => import('./components/QuizSummary'));
const AllSolution = lazy(() => import('./components/AllSolution'));
const IncorrectSolution = lazy(() => import('./components/IncorrectSolution'));
const Bookmark = lazy(() => import('./components/Bookmark'));
const ReviewQuestion = lazy(() => import('./components/ReviewQuestion'));
const SkillData = lazy(() => import('./components/SkillData'));
const AccountSetting = lazy(()=>import('./components/AccountSetting'));

const Routes = () => {
  return (
    <Suspense
      fallback={(
        <Box width='100vw' height='100vh' textAlign='center' lineHeight='100vh'>
          <LinearProgress />
        </Box>
      )}
    >
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/home-page" component={HomePage} />
          <ProtectedRoute exact path="/create-custom-quiz" component={CreateCustomQuiz} />
          <ProtectedRoute exact path="/quiz/question" component={DoExam} />
          <ProtectedRoute exact path="/quiz/quizsummary" component={QuizSummary} />
          <ProtectedRoute exact path="/quiz/solution/all" component={AllSolution} />
          <ProtectedRoute exact path="/quiz/solution/incorrect" component={IncorrectSolution} />
          <ProtectedRoute exact path="/bookmark" component={Bookmark} />
          <ProtectedRoute exact path="/quiz/review-question" component={ReviewQuestion} />
          <ProtectedRoute exact path="/skill-data" component={SkillData} />
          <ProtectedRoute exact path="/account-setting" component={AccountSetting}/>
          <Redirect from="/" to="/home-page" />
        </Switch>
        <SnackbarCustom />
      </Router>
    </Suspense>
  )
}

export default Routes;