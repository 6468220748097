export const paths = {};

// Users
paths.login = "users/login"
paths.getBasicStat = "users/getBasicStat"
paths.get = "users/get"
paths.sendAnswer = "users/sendAnswer"
paths.skillData = "users/skillData";

// Question
paths.getQuiz = "questions/getQuiz";
paths.getCount = "questions/getCount";
paths.getCountByCategory = "questions/getCountByCategory";
paths.bookmark = "questions/bookmark";
paths.category = "category";
paths.source = "source";
paths.user = "users";