import { types } from '../types';

const initialState = {
  quizzes: [],
  timeQuiz: 0,
  listQuiz: [],
  listAnswer: [],
  allSolution: [],
  inCorrectSolution: [],
  listAnswerWrong: [],
  indexSolution: 0,
  listReviewQuestion: [],
  indexViewQuestion: 0,
  snackbarState: {
    open: false,
    vertical: 'top',
    horizontal: 'right',
    message: ''
  }
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OPEN_SNACKBAR_STATE:
      return {
        ...state,
        snackbarState: {
          open: true,
          vertical: 'top',
          horizontal: 'right',
          message: action.payload.message
        }
      }
    case types.SET_CLOSE_SNACKBAR_STATE:
      return {
        ...state,
        snackbarState: {
          open: false,
          vertical: 'top',
          horizontal: 'right',
          message: ''
        }
      }
    case types.GET_QUIZ:
      return {
        ...state,
        quizzes: action.payload.message
      }
    case types.SET_TIME_QUIZ:
      return {
        ...state,
        timeQuiz: action.payload.message
      }
    case types.SET_LIST_QUIZ:
      return {
        ...state,
        listQuiz: action.payload.message
      }
    case types.SET_LIST_ANSWER:
      return {
        ...state,
        listAnswer: action.payload.message
      }
    case types.SET_ALL_SOLUTION:
      return {
        ...state,
        allSolution: action.payload.message
      }
    case types.SET_INCORRECT_SOLUTION:
      return {
        ...state,
        inCorrectSolution: action.payload.message
      }
    case types.SET_LIST_ANSWER_WRONG:
      return {
        ...state,
        listAnswerWrong: action.payload.message
      }
    case types.SET_INDEX_SOLUTION:
      return {
        ...state,
        indexSolution: action.payload.message
      }
    case types.SET_LIST_REVIEW_QUESTION:
      return {
        ...state,
        listReviewQuestion: action.payload.message
      }
    case types.SET_INDEX_VIEW_QUESTION:
      return {
        ...state,
        indexViewQuestion: action.payload.message
      }
    default: return state;
  }
}